import SEO from "components/SEO";
import StoreLocator, { Store } from "components/StoreLocator";
import Wrapper from "components/Wrapper";
import { graphql } from "gatsby";
import { useTranslation } from "hooks/useTranslation";
import React from "react";
import styled from "styled-components";

interface Props {
  data: {
    allDatoCmsStore: {
      nodes: Store[];
    };
  };
  pageContext: {
    locale: string;
    defaultLocale: string;
    allPathLocales: any[];
    pagePath: string;
  };
}

/**
 * Store locator page wrapper
 * Just to allow useContext usage inside
 */
const StoreLocatorPage: React.SFC<Props> = ({ data, pageContext }) => {
  const { formatMessage } = useTranslation();
  const { pagePath } = pageContext;

  const stores = data.allDatoCmsStore.nodes;

  return (
    <Wrapper>
      <SEO
        title={formatMessage({ id: "SEO.storeLocatorTitle" })}
        link={[
          { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
        ]}
      />
      <Title>{formatMessage({ id: "storeLocator.title" })}</Title>
      <StoreLocator stores={stores} />
    </Wrapper>
  );
};

const Title = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  padding-top: 30px;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

export const query = graphql`
  query storeLocatorQuery($locale: String) {
    allDatoCmsStore(filter: { locale: { eq: $locale } }) {
      nodes {
        id
        name
        address
        zipCode
        city
        country
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export default StoreLocatorPage;
